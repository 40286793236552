import React from 'react'

import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import SetNewPasswordForm from '../components/SetNewPasswordForm'

export default () => {
  return (
    <Layout>
      <SEO title="Reset Password" />
      <Hero
        title="Reset Password"
        className="challenge-type"
        img={require('../assets/imgs/5.jpg')}
      />
      <SetNewPasswordForm />
    </Layout>
  )
}
